import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import translatePolicyStatement from '../../utils/translatePolicy';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  maxHeight: 'calc(100vh - 200px)',
  '&::-webkit-scrollbar': {
    width: '0.4em',
    height: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: '4px',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '16px',
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
  },
  '&.MuiTableCell-body': {
    fontSize: '0.875rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const EffectCell = styled(StyledTableCell)(({ effect, theme }) => ({
  color: effect === 'Allow' ? theme.palette.success.main : theme.palette.error.main,
  fontWeight: 600,
}));

const DescriptionCell = styled(StyledTableCell)({
  maxWidth: '300px',
  overflowWrap: 'break-word',
});

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

const PolicyTranslation = ({ policy }) => {
  if (!policy || !policy.Statement || policy.Statement.length === 0) {
    return (
      <EmptyState>
        <Typography variant="body1" color="textSecondary">
          No policy data available. Please enter a valid IAM policy.
        </Typography>
      </EmptyState>
    );
  }

  const renderTableRows = () => {
    let rows = [];
    policy.Statement.forEach((statement, statementIndex) => {
      const actions = Array.isArray(statement.Action) ? statement.Action : [statement.Action];
      const resources = Array.isArray(statement.Resource) ? statement.Resource : [statement.Resource];
      const rowCount = Math.max(actions.length, resources.length);

      for (let i = 0; i < rowCount; i++) {
        rows.push(
          <StyledTableRow key={`${statementIndex}-${i}`}>
            {i === 0 && (
              <StyledTableCell rowSpan={rowCount} align="center">
                {statementIndex + 1}
              </StyledTableCell>
            )}
            <StyledTableCell>{actions[i] || ''}</StyledTableCell>
            <StyledTableCell>{resources[i] || ''}</StyledTableCell>
            <EffectCell effect={statement.Effect}>{statement.Effect}</EffectCell>
            {i === 0 && (
              <DescriptionCell
                rowSpan={rowCount}
                dangerouslySetInnerHTML={{
                  __html: translatePolicyStatement(statement)
                }}
              />
            )}
          </StyledTableRow>
        );
      }
    });
    return rows;
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table stickyHeader aria-label="policy translation table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center">Statement</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
            <StyledTableCell>Resource</StyledTableCell>
            <StyledTableCell>Effect</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {renderTableRows()}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default PolicyTranslation;