import React, { useState, useEffect } from 'react';
import { ResizableBox } from 'react-resizable';
import { FaGithub } from 'react-icons/fa'; 
import FileUploader from './components/FileUploader/FileUploader';
import TreeView from './components/TreeView/TreeView';
import JsonInput from './components/JsonInput/JsonInput';
import Visualization from './components/Visualization/Visualization';
import PolicyTranslation from './components/PolicyTranslation/PolicyTranslation';
import SecurityScan from './components/SecurityScan/SecurityScan';
import 'react-resizable/css/styles.css';
import './App.css';

const defaultPolicy = {
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": [
        "ec2:StartInstances",
        "lambda:InvokeFunction"
      ],
      "Resource": "*"
    },
    {
      "Effect": "Deny",
      "Action": [
        "s3:DeleteObject",
        "ec2:*",
        "lambda:DeleteFunction",
        "cloudformation:*"
      ],
      "Resource": "*"
    },
    {
      "Sid": "AllowListBucketWithConditions",
      "Effect": "Allow",
      "Action": "s3:ListBucket",
      "Resource": "*",
      "Condition": {
        "StringEquals": {
          "aws:PrincipalTag/department": [
            "finance",
            "hr",
            "legal"
          ],
          "aws:PrincipalTag/role": [
            "audit",
            "security"
          ]
        },
        "ArnLike": {
          "aws:PrincipalArn": [
            "arn:aws:iam::222222222222:user/Ana",
            "arn:aws:iam::222222222222:user/Mary"
          ]
        }
      }
    },
    {
      "Sid": "ExamplePolicy",
      "Effect": "Allow",
      "Principal": {
        "AWS": "arn:aws:iam::222222222222:root"
      },
      "Action": "s3:ListBucket",
      "Resource": "arn:aws:s3:::DOC-EXAMPLE-BUCKET",
      "Condition": {
        "StringEquals": {
          "aws:PrincipalTag/department": [
            "finance",
            "hr",
            "legal"
          ],
          "aws:PrincipalTag/role": [
            "audit",
            "security"
          ]
        },
        "ArnLike": {
          "aws:PrincipalArn": [
            "arn:aws:iam::222222222222:user/Ana",
            "arn:aws:iam::222222222222:user/Mary"
          ]
        }
      }
    }
  ]
};

const App = () => {
  const [treeData, setTreeData] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(defaultPolicy);
  const [jsonInput, setJsonInput] = useState(JSON.stringify(defaultPolicy, null, 2));
  const [activeTab, setActiveTab] = useState('visualization');
  const [isValidJson, setIsValidJson] = useState(true);

  useEffect(() => {
    try {
      if (jsonInput.trim() !== '') {
        const parsedPolicy = JSON.parse(jsonInput);
        setSelectedPolicy(parsedPolicy);
        setIsValidJson(true);
      } else {
        setSelectedPolicy(null);
        setIsValidJson(false);
      }
    } catch (error) {
      console.error('Invalid JSON input:', error);
      setSelectedPolicy(null);
      setIsValidJson(false);
    }
  }, [jsonInput]);

  const handleFileUpload = (uploadedTreeData) => {
    setTreeData(uploadedTreeData);
  };

  const handlePolicySelect = (policy) => {
    setSelectedPolicy(policy);
    setJsonInput(JSON.stringify(policy, null, 2));
  };

  const handleJsonInputChange = (newJsonInput) => {
    setJsonInput(newJsonInput);
  };

  const renderActiveTab = () => {
    switch(activeTab) {
      case 'visualization':
        return <Visualization jsonValue={jsonInput} />;
      case 'policyTranslation':
        return <PolicyTranslation policy={isValidJson ? selectedPolicy : null} />;
      case 'securityScan':
        return <SecurityScan policy={isValidJson ? selectedPolicy : null} />;
      default:
        return <Visualization jsonValue={jsonInput} />;
    }
  };

  return (
    <div className="iam-policy-visualizer">
      <header className="header">
        <div className="header-content">
          <div className="header-left">
          </div>
          <h1 className="app-title">AWS IAM Policy Visualizer</h1>
          <div className="header-actions">
            <a 
              href="https://github.com/BourAbdelhadi/awsviz" 
              target="_blank" 
              rel="noopener noreferrer"
              className="github-link"
              aria-label="View source on GitHub"
            >
              <FaGithub />
            </a>
            <a 
              href="https://www.buymeacoffee.com/awsviz" 
              target="_blank" 
              rel="noopener noreferrer"
              className="buy-me-coffee-button"
            >
              <img 
                src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" 
                alt="Buy Me A Coffee" 
              />
            </a>
          </div>
        </div>
      </header>
      <div className="content-wrapper">
        <ResizableBox
          width={550}
          height={Infinity}
          minConstraints={[300, Infinity]}
          maxConstraints={[800, Infinity]}
          className="left-panel"
          handle={<div className="custom-handle custom-handle-e" />}
          resizeHandles={['e']}
        >
          <FileUploader onUpload={handleFileUpload} />
          <div className="tree-json-container">
            <ResizableBox
              width={Infinity}
              height={300}
              minConstraints={[Infinity, 100]}
              maxConstraints={[Infinity, Infinity]}
              className="tree-view-container"
              handle={<div className="custom-handle custom-handle-s" />}
              resizeHandles={['s']}
            >
              <TreeView data={treeData} onSelect={handlePolicySelect} />
            </ResizableBox>
            <div className="json-input-container">
              <JsonInput value={jsonInput} onChange={handleJsonInputChange} />
            </div>
          </div>
        </ResizableBox>
        <div className="right-panel">
          <div className="tabs">
            <button 
              className={`tab ${activeTab === 'visualization' ? 'active' : ''}`}
              onClick={() => setActiveTab('visualization')}
            >
              Visualization
            </button>
            <button 
              className={`tab ${activeTab === 'policyTranslation' ? 'active' : ''}`}
              onClick={() => setActiveTab('policyTranslation')}
            >
              Policy Translation
            </button>
            <button 
              className={`tab ${activeTab === 'securityScan' ? 'active' : ''}`}
              onClick={() => setActiveTab('securityScan')}
            >
              Security Scan
            </button>
          </div>
          <div className="tab-content">
            {renderActiveTab()}
          </div>
        </div>
      </div>
      <footer className="footer">
        Built with ❤️ by <a href="https://www.linkedin.com/in/bohr/" target="_blank" rel="noopener noreferrer">Bour Abdelhadi</a>
      </footer>
    </div>
  );
};

export default App;