import React from 'react';

const SecurityScan = ({ policy }) => {
  // This is a placeholder
  const securityIssues = [
    { severity: 'High', issue: 'Overly permissive S3 access' },
    { severity: 'Medium', issue: 'Unused EC2 permissions' },
    { severity: 'Low', issue: 'Missing resource constraints' },
  ];

  return (
    <div className="security-scan">
      <h2>Security Scan</h2>
      <table>
      Coming soon :D !
      </table>
    </div>
  );
};

export default SecurityScan;
