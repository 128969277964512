import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import { FaCloudUploadAlt } from 'react-icons/fa';
import './FileUploader.css';

const FileUploader = ({ onUpload }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.name.endsWith('.zip')) {
      const zip = new JSZip();
      const contents = await zip.loadAsync(file);
      const treeData = { name: file.name, children: [] };
      
      for (const [filename, zipEntry] of Object.entries(contents.files)) {
        if (!zipEntry.dir) {
          const content = await zipEntry.async('string');
          try {
            const policy = JSON.parse(content);
            treeData.children.push({ name: filename, policy });
          } catch (error) {
            console.error(`Error parsing ${filename}:`, error);
          }
        }
      }
      
      onUpload(treeData);
    } else if (file.name.endsWith('.json')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const policy = JSON.parse(event.target.result);
          onUpload({ name: file.name, children: [{ name: file.name, policy }] });
        } catch (error) {
          console.error('Error parsing JSON file:', error);
        }
      };
      reader.readAsText(file);
    }
  }, [onUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="file-uploader">
      <input {...getInputProps()} />
      <div className="uploader-content">
        <FaCloudUploadAlt className="upload-icon" />
        <p className="upload-text">
          {isDragActive
            ? "Drop the files here ..."
            : "Drag 'n' drop your IAM zip file here, or click to upload."}
        </p>
      </div>
    </div>
  );
};

export default FileUploader;