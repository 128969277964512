import React, { useState } from 'react';
import { FaFolder, FaFolderOpen, FaFile, FaClipboard } from 'react-icons/fa';
import './TreeView.css';

const TreeNode = ({ node, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = node.children && node.children.length > 0;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = () => {
    onSelect(node.policy || node);
  };

  return (
    <div className="tree-node">
      <div className="node-content" onClick={hasChildren ? toggleOpen : handleSelect}>
        {hasChildren ? (
          isOpen ? <FaFolderOpen className="icon" /> : <FaFolder className="icon" />
        ) : (
          <FaFile className="icon" />
        )}
        <span className="node-name">{node.name}</span>
      </div>
      {hasChildren && isOpen && (
        <div className="node-children">
          {node.children.map((childNode, index) => (
            <TreeNode key={index} node={childNode} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeView = ({ data, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const filterTree = (node, term) => {
    if (node.name.toLowerCase().includes(term.toLowerCase())) {
      return node;
    }
    if (node.children) {
      const filteredChildren = node.children
        .map(child => filterTree(child, term))
        .filter(child => child !== null);
      if (filteredChildren.length > 0) {
        return { ...node, children: filteredChildren };
      }
    }
    return null;
  };

  const filteredData = data && searchTerm ? filterTree(data, searchTerm) : data;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const codeSnippets = [
    'curl -O https://raw.githubusercontent.com/BourAbdelhadi/awsviz/main/download-policies.sh',
    'export AWS_ACCESS_KEY_ID=your_access_key_id\nexport AWS_SECRET_ACCESS_KEY=your_secret_access_key',
    'chmod +x download-policies.sh',
    './download-policies.sh'
  ];

  return (
    <div className="tree-view">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={!data}
        />
      </div>
      <div className="tree-content">
        {filteredData ? (
          <TreeNode node={filteredData} onSelect={onSelect} />
        ) : (
          <div>
            No IAM JSON found. Upload a zip file.{' '}
            <button onClick={handleOpenModal} className="how-to-upload-button">Get ZIP Instructions</button>
          </div>
        )}
      </div>
      {showModal && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal">
            <h2>How to Upload Your IAM JSON ZIP File</h2>
            <button className="closeButton" onClick={handleCloseModal}>X</button>
            <div className="modalContent">
              <p>Follow these steps to download and run the bash script to gather all IAM policies into a zip file:</p>
              <ol>
                <li>Download the bash script by running:</li>
                <div className="codeContainer">
                  <pre><code>{codeSnippets[0]}</code></pre>
                  <FaClipboard
                    className="clipboardIcon"
                    onClick={() => copyToClipboard(codeSnippets[0], 0)}
                    color={copiedIndex === 0 ? 'green' : 'black'}
                  />
                </div>
                <li>Navigate to the directory where the script is downloaded.</li>
                <li>Set up your AWS credentials:</li>
                <div className="codeContainer">
                  <pre><code>{codeSnippets[1]}</code></pre>
                  <FaClipboard
                    className="clipboardIcon"
                    onClick={() => copyToClipboard(codeSnippets[1], 1)}
                    color={copiedIndex === 1 ? 'green' : 'black'}
                  />
                </div>
                <li>Give execution permission to the script:</li>
                <div className="codeContainer">
                  <pre><code>{codeSnippets[2]}</code></pre>
                  <FaClipboard
                    className="clipboardIcon"
                    onClick={() => copyToClipboard(codeSnippets[2], 2)}
                    color={copiedIndex === 2 ? 'green' : 'black'}
                  />
                </div>
                <li>Run the script:</li>
                <div className="codeContainer">
                  <pre><code>{codeSnippets[3]}</code></pre>
                  <FaClipboard
                    className="clipboardIcon"
                    onClick={() => copyToClipboard(codeSnippets[3], 3)}
                    color={copiedIndex === 3 ? 'green' : 'black'}
                  />
                </div>
                <li>The script will create a zip file containing all your IAM policy JSON files.</li>
                <li>Once you have the zip file, you can upload it on the main page to visualize the policies.</li>
              </ol>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TreeView;
