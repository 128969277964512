import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';

const JsonInput = ({ value, onChange }) => {
  return (
    <AceEditor
      mode="json"
      theme="tomorrow"
      onChange={onChange}
      value={value}
      name="json-editor"
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        useWorker: false,
        showLineNumbers: true,
        tabSize: 2,
        fontSize: 14,
        fontFamily: "'Source Code Pro', monospace",
      }}
      width="100%"
      height="100%"
    />
  );
};

export default JsonInput;