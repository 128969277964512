const awsIcons = {
    s3: 'assets/service-icons/s3.svg',
    lambda: 'assets/service-icons/lambda.svg',
    ec2: 'assets/service-icons/ec2.svg', 
    cloudformation: 'assets/service-icons/cf.svg',
    dynamodb: 'assets/service-icons/ddb.svg',
    rds: 'assets/service-icons/rds.svg',
    sns: 'assets/service-icons/sns.svg',
    sqs: 'assets/service-icons/sqs.svg',
    iam: 'assets/service-icons/iam.svg',
    cloudwatch: 'assets/service-icons/cloudwatch.svg',
    eks: 'assets/service-icons/eks.svg',
    ecs: 'assets/service-icons/ecs.svg',
    states: 'assets/service-icons/states.svg',
    athena: 'assets/service-icons/athena.svg',
    glue: 'assets/service-icons/glue.svg',
    kinesis: 'assets/service-icons/kinesis.svg',
    cloudtrail: 'assets/service-icons/cloudtrail.svg',
    elasticbeanstalk: 'assets/service-icons/elasticbeanstalk.svg',
    amplify: 'assets/service-icons/amplify.svg',
    appsync: 'assets/service-icons/appsync.svg',
    batch: 'assets/service-icons/batch.svg',
    acm: 'assets/service-icons/acm.svg',
    cloudfront: 'assets/service-icons/cloudfront.svg',
    codeartifact: 'assets/service-icons/codeartifact.svg',
    codebuild: 'assets/service-icons/codebuild.svg',
    codecommit: 'assets/service-icons/codecommit.svg',
    codedeploy: 'assets/service-icons/codedeploy.svg',
    codepipeline: 'assets/service-icons/codepipeline.svg',
    config: 'assets/service-icons/config.svg',
    elasticfilesystem: 'assets/service-icons/elasticfilesystem.svg',
    route53: 'assets/service-icons/route53.svg',
    redshift: 'assets/service-icons/redshift.svg',
    quicksight: 'assets/service-icons/quicksight.svg',
    ses: 'assets/service-icons/ses.svg',
    ssm: 'assets/service-icons/ssm.svg',
    waf: 'assets/service-icons/waf.svg',
    kms: 'assets/service-icons/kms.svg',
    organizations: 'assets/service-icons/organizations.svg',
    secretsmanager: 'assets/service-icons/secretsmanager.svg',
    ecr: 'assets/service-icons/ecr.svg',
    logs: 'assets/service-icons/cw.svg',
    sts: 'assets/service-icons/sts.svg',

    
    // Add more mappings as necessary
  };
  
  export const getIconForAction = (action) => {
    const service = action.split(':')[0];
    return awsIcons[service] || 'assets/service-icons/default.svg'; // Fallback to a default icon if service not found
  };