import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { getIconForAction } from '../../utils/iconMapper';

const Visualization = ({ jsonValue }) => {
  const svgRef = useRef();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!jsonValue) {
      clearVisualization();
      return;
    }
    try {
      const policy = typeof jsonValue === 'string' ? JSON.parse(jsonValue) : jsonValue;
      if (!policy || typeof policy !== 'object' || !policy.Statement || !Array.isArray(policy.Statement)) {
        throw new Error('Invalid policy structure');
      }
      setData(policy);
    } catch (error) {
      console.error('Error parsing or validating policy:', error);
      clearVisualization();
      setData(null);
    }
  }, [jsonValue]);

  const clearVisualization = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
  };

  useEffect(() => {
    if (!data || !data.Statement || !Array.isArray(data.Statement)) {
      clearVisualization();
      return;
    }

    clearVisualization();

    const width = svgRef.current.clientWidth;
    const height = svgRef.current.clientHeight;
    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    const g = svg.append('g');

    const zoom = d3.zoom()
      .scaleExtent([0.5, 3])
      .on('zoom', (event) => {
        g.attr('transform', event.transform);
      });

    svg.call(zoom);

    const root = d3.hierarchy({
      name: 'IAM Policy',
      children: data.Statement.map((statement, index) => ({
        name: `Statement ${index + 1}`,
        effect: statement.Effect,
        children: [
          {
            name: 'Actions',
            children: (Array.isArray(statement.Action) ? statement.Action : [statement.Action])
              .filter(Boolean)
              .map(action => ({
                name: action,
                icon: getIconForAction(action)
              }))
          },
          statement.NotAction ? {
            name: 'NotActions',
            children: (Array.isArray(statement.NotAction) ? statement.NotAction : [statement.NotAction])
              .filter(Boolean)
              .map(action => ({
                name: action,
                icon: getIconForAction(action)
              }))
          } : null,
          {
            name: 'Resources',
            children: (Array.isArray(statement.Resource) ? statement.Resource : [statement.Resource])
              .filter(Boolean)
              .map(resource => ({
                name: resource
              }))
          },
          statement.Condition ? {
            name: 'Conditions',
            children: Object.entries(statement.Condition).map(([conditionType, conditionValues]) => ({
              name: conditionType,
              children: Object.entries(conditionValues).map(([conditionKey, conditionValue]) => ({
                name: `${conditionKey}: ${conditionValue}`
              }))
            }))
          } : null
        ].filter(Boolean)
      }))
    });

    root.x0 = height / 2;
    root.y0 = 0;

    const treeLayout = d3.tree().nodeSize([30, 180]);

    let i = 0;

    const update = (source) => {
      const treeData = treeLayout(root);
      const nodes = treeData.descendants();
      const links = treeData.links();

      nodes.forEach(d => {
        d.y = d.depth * 180;
      });

      const node = g.selectAll('g.node')
        .data(nodes, d => d.id || (d.id = ++i));

      const nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', d => `translate(${source.y0},${source.x0})`)
        .on('click', (event, d) => {
          toggleChildren(d);
          update(d);
        })
        .on('mouseover', function () {
          d3.select(this).select('circle').attr('stroke', 'orange');
          d3.select(this).style('cursor', 'pointer');
        })
        .on('mouseout', function () {
          d3.select(this).select('circle').attr('stroke', d => d.data.effect === 'Allow' ? 'green' : (d.data.effect === 'Deny' ? 'red' : '#69b3a2'));
          d3.select(this).style('cursor', 'default');
        });

      nodeEnter.append('circle')
        .attr('r', 1e-6)
        .attr('fill', d => d._children ? 'lightsteelblue' : '#fff')
        .attr('stroke', d => d.data.effect === 'Allow' ? 'green' : (d.data.effect === 'Deny' ? 'red' : '#69b3a2'))
        .attr('stroke-width', 3);

      nodeEnter.each(function (d) {
        if (d.data.icon) {
          d3.select(this).append('image')
            .attr('xlink:href', d.data.icon)
            .attr('x', -12)
            .attr('y', -12)
            .attr('width', 24)
            .attr('height', 24);
        }
      });

      nodeEnter.append('text')
        .attr('dy', 3)
        .attr('x', d => d.children || d._children ? -15 : 15)
        .attr('text-anchor', d => d.children || d._children ? 'end' : 'start')
        .text(d => d.data.name);

      const nodeUpdate = nodeEnter.merge(node);

      nodeUpdate.transition()
        .duration(300)
        .attr('transform', d => `translate(${d.y},${d.x})`);

      nodeUpdate.select('circle')
        .attr('r', 10)
        .attr('fill', d => d._children ? 'lightsteelblue' : '#fff')
        .attr('stroke', d => d.data.effect === 'Allow' ? 'green' : (d.data.effect === 'Deny' ? 'red' : '#69b3a2'))
        .attr('stroke-width', 3);

      const nodeExit = node.exit().transition()
        .duration(300)
        .attr('transform', d => `translate(${source.y},${source.x})`)
        .remove();

      nodeExit.select('circle')
        .attr('r', 1e-6);

      nodeExit.select('text')
        .style('fill-opacity', 1e-6);

      const link = g.selectAll('path.link')
        .data(links, d => d.target.id);

      const linkEnter = link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', d => {
          const o = { x: source.x0, y: source.y0 };
          return diagonal(o, o);
        })
        .attr('fill', 'none')
        .attr('stroke', '#ccc')
        .attr('stroke-width', 1);

      const linkUpdate = linkEnter.merge(link);

      linkUpdate.transition()
        .duration(300)
        .attr('d', d => diagonal(d.source, d.target));

      link.exit().transition()
        .duration(300)
        .attr('d', d => {
          const o = { x: source.x, y: source.y };
          return diagonal(o, o);
        })
        .remove();

      nodes.forEach(d => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    };

    const diagonal = (s, d) => {
      return `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;
    };

    const toggleChildren = (d) => {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
    };

    function dragStart(event, d) {
      d3.select(this).raise().classed('active', true);
    }

    function dragged(event, d) {
      d.x = event.y;
      d.y = event.x;
      d3.select(this).attr('transform', `translate(${d.y},${d.x})`);
      g.selectAll('path.link')
        .attr('d', link => diagonal(link.source, link.target));
    }

    function dragEnd(event, d) {
      d3.select(this).classed('active', false);
      update(d);
    }

    function collapse(d) {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
      }
    }

    root.children.forEach(collapse);
    update(root);

    // Center the root node once after the initial render
    const initialTransform = d3.zoomIdentity.translate(width / 2 - root.y0, height / 2 - root.x0);
    svg.call(zoom.transform, initialTransform);

  }, [data]);

  return (
    <svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
  );
};

export default Visualization;
